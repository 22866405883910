<template>
  <div class="account">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="
          account__header__items
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <div class="account__logo">
          <!-- <LogoBlack /> -->
        </div>
        <Country />
      </div>
    </div>
    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <h1 class="account__head__title">hello</h1>
              <p class="account__head__subtitle">Reset Password</p>
            </div>
            <div class="account__body">
              <div class="account__form">
                <form class="form" @submit.prevent="reset">
                  <BaseInput
                    label="otp"
                    placeholder="OTP"
                    id="otp1"
                    myRef="otp1"
                    autofocus
                    required
                    v-model="otp"
                    type="text"
                  >
                  </BaseInput>
                  <BaseInput
                    label="Username"
                    placeholder="Username"
                    id="Username"
                    :myRef="null"
                    autofocus
                    required
                    v-model="username"
                    type="text"
                  >
                  </BaseInput>
                  <BaseInput
                    label="password"
                    placeholder="Password"
                    id="password"
                    :myRef="null"
                    autofocus
                    required
                    v-model="password"
                    type="password"
                  >
                  </BaseInput>
                  <div
                    class="
                      form__item form__action
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <div style="position: relative; z-index: 3">
                      <button
                        type="submit"
                        role="button"
                        href="#"
                        class="button form__button form__button--lg"
                      >
                        {{ process ? "please wait..." : "Reset" }}
                      </button>
                      <img
                        style="
                          position: absolute;
                          left: -5rem;
                          top: -2.5rem;
                          z-index: -1;
                        "
                        src="/assets/img/dotted-bg.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <router-link to="/auth/login" class="form__link">
                      Back to Login
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
            <div class="account__info mt-auto">
              <div class="account__info__container d-flex align-items-center">
                <div class="account__info__icon">
                  <img src="assets/img/help.svg" alt="" srcset="" />
                </div>
                <div class="">
                  <span class="account__text"
                    >Expert support available to you 24 /7.</span
                  >
                  <span class="account__text account__info__link text--capital">
                    Contact Support
                  </span>
                </div>
                <!-- <div class="account__info__logo">
                  <img src="assets/img/logo-black.svg" alt="" srcset="" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoBlack from "@/components/UI/_base-logoBlack.vue";
import Country from "@/components/_base-country-svg.vue";
import BaseInput from "@/components/Inputs/_base-Input.vue";
import { RESET_PASSWORD } from "@/core/services/store/types";
import { required } from "vuelidate/lib/validators";

export default {
  name: "passwordReset",

  data() {
    return {
      username: "",
      password: "",
      otp: "",
      process: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
    otp: {
      required,
    },
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    async reset() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.alertError("Otp, Username and password are required");
        return;
      }
      this.process = true;
      this.$store
        .dispatch(RESET_PASSWORD, {
          username: this.username,
          passwordResetToken: this.otp,
          newPassword: this.password,
        })
        // go to which page after successfully login
        .then((res) => {
          this.process = false;
          this.alertSuccess(res.message);
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        })
        .catch((e) => {
          this.process = false;
          this.alertError(e.data.message);
        });
    },
  },

  components: {
    // LogoBlack,
    Country,
    BaseInput,
  },
};
</script>
